import { Routes } from '@angular/router';
import { WeatherComponent } from './weather/weather.component';

export const routes: Routes = [
    {
      /*path: '',
      redirectTo: '/',
      pathMatch: 'full',
    },*/
      path: '',
      component: WeatherComponent
    }
];
